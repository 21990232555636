<template>
  <div class="youth-home-stats" v-if="hasPerm('reports.view_')">
    <page-header
      title="Répartition par commune"
      icon="fas fa-chart-bar"
      :links="getLinks()"
    >
    </page-header>
    <div class="city-report">
      <b-row>
        <b-col cols="12">
          <loading-gif :loading-name="statsLoading"></loading-gif>
          <b-row v-show="!isLoading(statsLoading) && showContent && !generateMode">
            <b-col cols="9">
              <date-range-selector @change="onDateRangeChanged" civil-first></date-range-selector>
            </b-col>
            <b-col cols="3">
              <div class="btn-holder">
                <a class="btn btn-primary btn-block" @click.prevent="loadData()" :class="{ disabled: !isLoadStatsActive, }">
                  Rafraîchir
                </a>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <generate-youth-home-stats
                v-show="!isLoading(statsLoading)"
                :start-date="startDate"
                :end-date="endDate"
                @generateMode="generateMode = $event.mode"
                @generating="disablePage"
                @generated="reloadPage"
              >
              </generate-youth-home-stats>
            </b-col>
          </b-row>
          <div v-if="!isLoading(statsLoading) && showContent" ref="printMe">
            <b-row >
              <b-col cols="12">
                <div class="stats" ref="excelMe">
                  <table class="table table-full table-bordered table-striped small-text">
                    <tbody v-for="group of groups" :key="group.id">
                      <tr>
                        <th :colspan="1 + (3 * (1 + socialRegimes.length))">{{ group.name }}</th>
                      </tr>
                      <tr>
                        <td>Commune</td>
                        <td
                          v-for="regime of socialRegimes"
                          :key="regime.id" colspan="3"
                          class="text-center"
                        >
                          {{ regime.name }}
                        </td>
                        <td colspan="3" class="total text-center">Total</td>
                      </tr>
                      <tr>
                        <th></th>
                        <td v-for="item of dataItems" :key="item.key" class="text-center">
                          {{ item.label }}
                        </td>
                        <td v-for="item of totalItems" :key="item.key" class="total text-center">
                          {{ item.label }}
                        </td>
                      </tr>
                      <tr v-for="city of cities" :key="city.id">
                        <th>{{ city.name }}</th>
                        <td v-for="item of dataItems" :key="item.key" class="number">
                          {{ getDataItem(group, city, item ) }}
                        </td>
                        <td v-for="item of totalItems" :key="item.key" class="total number">
                          {{ getTotalItem(group, city, item ) }}
                        </td>
                      </tr>
                      <td :colspan="1 + (3 * socialRegimes.length)"></td>
                    </tbody>
                  </table>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapActions, mapMutations } from 'vuex'
import LoadingGif from '@/components/Controls/LoadingGif.vue'
import PageHeader from '@/components/Layout/PageHeader.vue'
import GenerateYouthHomeStats from '@/components/Stats/GenerateYouthHomeStats.vue'
import DateRangeSelector from '@/components/DateRange/DateRangeSelector.vue'
import { BackendMixin } from '@/mixins/backend'
import { makeChoice } from '@/types/base'
import { makeAgeGroup } from '@/types/youth'
import { BackendApi, openDocument } from '@/utils/http'
import moment from 'moment'
import { dateToString } from '@/filters/texts'

export default {
  name: 'city-reports',
  mixins: [BackendMixin],
  components: {
    LoadingGif, PageHeader, GenerateYouthHomeStats, DateRangeSelector,
  },
  props: {
  },
  data() {
    return {
      statsLoading: 'city-reports',
      disabled: true,
      showContent: true,
      generateMode: false,
      startDate: null,
      endDate: null,
      fullData: null,
      durationMap: new Map(),
      groups: [],
      cities: [],
      socialRegimes: [],
      dataMap: new Map(),
    }
  },
  computed: {
    isLoadStatsActive() {
      return this.startDate && this.endDate
    },
    dataItems() {
      const items = []
      for (const regime of this.socialRegimes) {
        items.push(
          { key: 'childrenCount' + regime.id, field: 'childrenCount', regime: regime, label: 'Nb enfants', },
          { key: 'realDuration' + regime.id, field: 'realDuration', regime: regime, label: 'Durée réelle', },
          { key: 'paidDuration' + regime.id, field: 'paidDuration', regime: regime, label: 'Durée facturée', }
        )
      }
      return items
    },
    totalItems() {
      const items = []
      items.push(
        { key: 'childrenCount', field: 'childrenCount', label: 'Nb enfants', },
        { key: 'realDuration', field: 'realDuration', label: 'Durée réelle', },
        { key: 'paidDuration', field: 'paidDuration', label: 'Durée facturée', }
      )
      return items
    },
  },
  mounted() {
  },
  watch: {
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    async loadData() {
      this.startLoading(this.loadingName)
      await this.loadStats(this.detail)
      this.endLoading(this.loadingName)
    },
    getDataItem(group, city, item) {
      const key = '' + city.id + '#' + group.id + '#' + item.regime.id
      const obj = this.dataMap.get(key)
      if (obj) {
        return obj[item.field]
      }
      return 0
    },
    getTotalItem(group, city, item) {
      let total = 0
      for (const regime of this.socialRegimes) {
        const key = '' + city.id + '#' + group.id + '#' + regime.id
        const obj = this.dataMap.get(key)
        if (obj) {
          total += obj[item.field]
        }
      }
      return total
    },
    async loadStats() {
      if (!this.isLoadStatsActive) {
        return
      }
      this.startDataDate = this.startDate
      this.endDataDate = this.endDate
      const url = '/reports/api/city-report/?start_date=' + this.startDate + '&end_date=' + this.endDate
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.fullData = resp.data
        this.groups = resp.data.groups.map(makeAgeGroup)
        this.cities = resp.data.cities.map(makeChoice)
        this.socialRegimes = resp.data.social_regimes.map(makeChoice)
        const dataMap = new Map()
        for (const elt of resp.data['data']) {
          const city = elt['city']
          const regime = elt['social_regime']
          const group = elt['group_id']
          const key = '' + city + '#' + group + '#' + regime
          dataMap.set(
            key,
            {
              realDuration: elt['real_duration'],
              paidDuration: elt['paid_duration'],
              childrenCount: elt['children_count'],
            }
          )
        }
        this.dataMap = dataMap
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    getLinks() {
      return [
        {
          id: 1,
          label: 'Pdf',
          callback: this.printMe,
          icon: 'fa fa-file-pdf',
          cssClass: (this.isLoading(this.statsLoading)) ? 'btn-secondary disabled' : 'btn-secondary',
        },
        {
          id: 2,
          label: 'Excel',
          callback: this.excelMe,
          icon: 'fa fa-file-excel',
          cssClass: (this.isLoading(this.statsLoading)) ? 'btn-secondary disabled' : 'btn-secondary',
        }
      ]
    },
    async printMe() {
      const docUrl = '/documents/standard/<key>/pdf/'
      const docSlug = 'repartition-par-commune-' + moment().format('YYYY-MM-DD')
      const docContent = this.$refs.printMe.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent, 'dossier_caf')
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async excelMe() {
      const docUrl = '/documents/table-to-excel/<key>/'
      const docSlug = 'repartition-par-commune-' + moment().format('YYYY-MM-DD')
      const docContent = this.$refs.excelMe.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    onDateRangeChanged(event) {
      this.startDate = event.startDate
      this.endDate = event.endDate
    },
    disablePage() {
      this.showContent = false
    },
    reloadPage() {
      this.showContent = true
      this.loadData()
    },
  },
}
</script>

<style scoped lang="less">
.number {
  text-align: right;
}
tr td.total {
  background: #fbff8d;
}
tr:nth-of-type(odd) td.total {
  background: #cfd385;
}
</style>
